import React, { useEffect } from "react";
import Helmet from "react-helmet";
import { graphql } from 'gatsby';

import './blog-post.scss';
import Container from "../components/Container/container";
import CTA from "../components/CTA/cta";
import fiona from "../assets/fiona.jpg";
import { useDataLayer } from "../hooks/useDataLayer";

const BlogPost = (props) => {

    const { dataLayer } = useDataLayer();
    const { markdownRemark: post } = props.data;
    const maxWidth = '700px';

    useEffect(() => {
        dataLayer.push({
            'author': post?.frontmatter?.author,
            'title': post?.frontmatter?.title,
            'dateCreated': post?.frontmatter?.date,
            'pageType': 'Blog',
            'event': 'Blog'
        }, 'Blog')
    }, [])

    return (
        <div className="blog-post">
            <Container style={{ maxWidth }}>
                <Helmet title={`Tearribles - ${post.frontmatter.title}`} />
                <div className="blog-post">
                    <h1 className="blog-post__title">{post.frontmatter.title}</h1>
                    <div className="blog-post__date">{post.frontmatter.date}</div>
                    <img src={fiona} className="blog-post__image" alt="Dog with Tearrible Toy" />
                    <div
                        className="blog-post__content"
                        dangerouslySetInnerHTML={{ __html: post.html }}
                    />
                </div>
                <div className="blog-post__cta-wrapper">
                    <CTA to="/products" title="GET YOUR TEARRIBLE" />
                </div>
            </Container>
        </div>
    )


}

export const pageQuery = graphql`
  query BlogPostByPath($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        path
        title
        image {
            src
        }
      }
    }
  }
`

export default BlogPost;